<template>
  <v-app>

    <!-- Desktop version: centered "Tabs" Menu -->
    <v-app-bar app fixed dense elevate-on-scroll dark color="secondary" class="d-none d-md-block">
      <v-tabs centered background-color="primary">
        <v-tab v-for="nav in navItems" :key="nav.title" :to="nav.link">
          <span>{{nav.title}}</span>
        </v-tab>
      </v-tabs>
      <router-view :slot="$vuetify.breakpoint.mdAndUp ? 'extension' : ''" name="tabs"></router-view>
    </v-app-bar>

    <!-- Mobile version: hamburger menu with nav drawer -->
    <v-app-bar app dense flat color="secondary" class="d-md-none">
      <v-spacer class="ml-auto"/>
      <router-view name="tabs"></router-view>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer app right temporary v-model="drawer" class="d-md-none">
      <v-list nav dense shaped>
        <v-list-item v-for="nav in navItems" :key="nav.title" :to="nav.link">
            <v-list-item-content><v-list-item-title>{{nav.title}}</v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  methods: {
    routeHasTabs() {
      return this.$route.meta && this.$route.meta.hasTabs
    }
  },
  data() {
    return {
      drawer: false,
      navItems: [
        {title: "Home", link: "/"},
        {title: "Resume", link: "/resume"},
        {title: "Publications", link: "/publications"},
        {title: "Contact", link: "/contact"}
      ]
    }
  },
};
</script>

<style>
  a {
    text-decoration: none;
  }
  * {
    word-break: break-word !important;
  }
  .image-border {
    border: solid;
    border-color: rgb(222, 226, 230); /* HACK matches primaryColorLight in main.js */
  }
  .avatar-image {
    border-radius: 50%;
    margin: auto;
  }
  .centered-block {
    margin: auto;
  }
  .aligned-right {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .center-content {
    align-items: center;
  }
  .hidden-anchor {
    display: block;
    position: relative;
    visibility: hidden;
  }
</style>
