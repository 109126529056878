<template>
    <v-col cols=12 class="pt-0" :class="{ 'mt-10': (this.index === undefined || this.index > 0) }">
        <a class="hidden-anchor" :class="[desktop ? 'section-anchor-desktop' : 'section-anchor-mobile']" :id="asHtmlId(name)"></a>
        <v-card
            flat
            color="secondary"
            class="px-3 py-2 mt-0 text-left text-h4 text-capitalize titleText--text">
            {{ name }}
        </v-card>
    </v-col>
</template>

<script>
import { asHtmlId } from '../helper'

export default {
    name: "SectionTitle",
    props: [ "name", "index" ],
    computed: {
        desktop: function() { return this.$vuetify.breakpoint.mdAndUp },
    },
    methods: {
        asHtmlId,
    },
}
</script>

<style scoped>
  .section-anchor-desktop {
      top: -110px;
  }
  .section-anchor-mobile {
      top: -60px;
  }
</style>
