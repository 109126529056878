<template>
    <v-row justify="center">
        <v-col cols=11 lg=8 xl=6>
            <v-row dense v-for="(pubs, section, index) in $options.bibliography" :key="section" >
                <SectionTitle :name="section" :index="index" />
                <v-col cols=12 v-for="pub in pubs" :key="pub.title">
                    <v-card hover outlined :href="pub.link || pub.pdflink" >
                        <v-card-title>{{ pub.title }}</v-card-title>
                        <v-card-subtitle>{{ publicationSubtitle(pub) }}</v-card-subtitle>
                        <v-card-text>{{ publicationText(pub) }}</v-card-text>
                        <v-card-actions>
                            <v-btn color="linkButton" class="linkButtonText--text" elevation="0" :href="pub.pdflink" v-if="pub.pdflink">PDF</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-spacer/>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import BIB_JSON from "../bibliography.json"
import SectionTitle from "./SectionTitle"

export default {
    name: "Publications",
    bibliography: BIB_JSON,
    components: { SectionTitle },
    methods: {
        publicationSubtitle(pub) {
            return "(" + pub.year + ") " + pub.authors
        },
        publicationText(pub) {
            return pub.publisher + ", "  + pub.conference
        }
    }
}
</script>

<style scoped>
</style>
