import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueRouter from 'vue-router'
import colors from 'vuetify/lib/util/colors'

import App from './App'
import Contact from './components/Contact'
import Publications from './components/Publications'
import PageTabs from './components/PageTabs'
import Resume from './components/Resume'
import Home from './components/Home'

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(Vuetify);

// Construct tabs for the Publications page
import BIB_JSON from "./bibliography.json"
let bibNavigation = []
for (const section in BIB_JSON) {
  bibNavigation.push({ name: section, target: section })
}

// Construct tabs for the Resume page
let resumeNavigation = [ { name: "Start", target: "Title" } ]
for (const section of [ "Experience", "Education", "Skills" ]) {
  resumeNavigation.push({ name: section, target: section })
}

const routes = [
    { path: '*', redirect: '/' },  
    { path: '/', name: 'Home', component: Home },
    { path: '/contact', name: 'Contact', component: Contact },

    { path: '/resume', name: 'Resume',
      components: { default: Resume, tabs: PageTabs },
      meta: { hasTabs: true },
      props: {
        default: true,
        tabs: { navigation: resumeNavigation, root_page: "/resume" },
      } },

    { path: '/publications', name: 'Publications',
      components: { default: Publications, tabs: PageTabs },
      meta: { hasTabs: true },
      props: {
        default: true,
        tabs: { navigation: bibNavigation, root_page: "/publications" },
      } },
]

const primaryColor = colors.cyan.darken4
const secondaryColor = colors.grey.lighten3
const primaryColorLight = colors.cyan.darken1

new Vue({
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: primaryColor,
          contactIcon: primaryColor,
          linkButton: primaryColor,
          secondary: secondaryColor,
          anchor: primaryColorLight,

          linkButtonText: colors.grey.lighten5,
          titleText: colors.grey.darken3,
        }
      }
    }
  }),
  router: new VueRouter({
      routes,
      mode: 'history',
      /* eslint-disable no-unused-vars */
      scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 } // Jump to top when chaning page
      }
      /* eslint-enable no-unused-vars */
  }),
}).$mount('#app')
