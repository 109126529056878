<template>
    <v-row justify="center">
        <v-btn v-for="link in navigation" :key="link.text" :to="link.link" elevation="0" color="linkButton" class="text-none linkButtonText--text ma-2">
            {{ link.text }}
        </v-btn>
    </v-row>
</template>

<script>
export default {
    name: "BottomNav",
    props: [ "navigation" ], // list of objects with .name and .target
}
</script>
