<template>
    <v-row justify="center">
        <v-col cols=11 sm=7 md=8 lg=6 xl=4>
            <v-row justify="center">
                <v-col cols=12 md=6 class="d-flex">
                    <v-card class="flex-grow-1" outlined>
                        <v-card-title>Contact</v-card-title>
                        <v-card-text>
                            <v-row justify="center">
                                <div>
                                    <div v-for="(contact, index) in contacts" :key="contact.text" :class="{'mt-5': index > 0}">
                                        <v-btn elevation="0" color="linkButton" class="px-5 text-none linkButtonText--text" :href="contact.link">
                                            <v-row class="flex-nowrap" align="center">
                                                <v-icon class="mr-2" color="linkButtonText" size=32>{{ contact.icon }}</v-icon>
                                                <span class="ml-2">{{ contact.text }}</span>
                                            </v-row>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=12 md=6 class="d-flex">
                    <v-card class="flex-grow-1" outlined>
                        <v-card-title>Impressum/Imprint</v-card-title>
                        <v-card-text class="text-body-1">
                            <p>
                                <span class="text-subtitle-2">Name:</span><br/>
                                Anton Gulenko, PhD
                            </p>
                            <p>
                                <span class="text-subtitle-2">Address:</span><br/>
                                Am Bischofskreuz 22, 79114 Freiburg
                            </p>
                            <p>
                                <span class="text-subtitle-2">Email:</span><br/>
                                <a href="mailto:anton.gulenko@gmail.com">anton.gulenko@gmail.com</a>
                            </p>
                            <p>
                                <span class="text-subtitle-2">Disclaimer:</span><br/>
                                This website contains links to external content.
                                All referenced sites belong to reputable institutions, companies, or platforms.
                                I am not responsible for the content of these websites.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=12 class="py-0 d-md-none"><BottomNav :navigation="bottomLinks" /></v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import BottomNav from "./BottomNav"

export default {
    name: "Contact",
    components: { BottomNav },
    data() {
        return {
            contacts: [
                { link: "mailto:anton.gulenko@gmail.com", text: "anton.gulenko@gmail.com", icon: "mdi-at" },
                { link: "tel:+4915253452132", text: "+49 152 53452132", icon: "mdi-phone" },
                { link: "https://www.linkedin.com/in/antongulenko", text: "antongulenko", icon: "mdi-linkedin" },
            ],
            bottomLinks: [
                { link: "/", text: "Home" },
                { link: "/resume", text: "Resume" },
                { link: "/publications", text: "Publications" },
            ]
        }
    }
}
</script>
