<template>
    <v-col cols=12 class="mb-4 pt-3 text-center text-h3 text-sm-h2 font-weight-light titleText--text" :class="extra_classes">
        <a :id="asHtmlId(id)" class="hidden-anchor" :class="[desktop ? 'title-anchor-desktop' : 'title-anchor-mobile']"></a>
        {{ text }}
    </v-col>
</template>

<script>
import { asHtmlId } from '../helper'

export default {
    name: "PageTitle",
    props: {
        "text": { type: String, default: "" },
        "id": { type: String, default: "Title" },
        "extra_classes": { type: String, default: "" }
    },
    computed: {
        desktop: function() { return this.$vuetify.breakpoint.mdAndUp },
    },
    methods: {
        asHtmlId
    }
}
</script>

<style scoped>
  .title-anchor-desktop {
      top: -120px; 
  }
  .title-anchor-mobile {
      top: -72px; 
  }
</style>
