<template>
    <v-row justify="center">
        <v-col cols=11 lg=10 xl=6>
            <v-row dense justify="center" align="center">
                <PageTitle text="Anton Gulenko, PhD" />
                <div class="pr-sm-3" :class="desktop ? [] : [ bigMobile ? 'col-sm-6' : 'col-10' ]">
                    <v-img src="/profile.jpg" class="image-border" :class="{'centered-block': !bigMobile}" alt="Resume picture" max-width=400 />
                </div>
                <div class="d-flex align-center justify-center justify-sm-start pl-sm-3 pl-md-8 pr-0" :class="{'col-12': !bigMobile}">
                    <div>
                        <div class="mt-2" v-for="item in contacts" :key="item.link">
                            <a :href="item.link">
                                <v-row class="flex-nowrap" align="center">
                                    <v-icon color="contactIcon" :size="desktop ? 58 : 45">{{ item.icon }}</v-icon>
                                    <span class="ml-2">{{ item.text }}</span>
                                </v-row>
                            </a>
                        </div>
                    </div>
                </div>

                <SectionTitle name="Experience" />
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Co-Founder & CTO<span :class="classes.date_box">04/2022 ‒ 01/2023</span></v-card-title>

                        <v-card-subtitle>At <a href="https://quantaleap.eu/">Quantaleap</a></v-card-subtitle>
                        <v-card-text>
                            <p>
                                <ul>
                                <li>Established technological and organizational foundation for a high-tech startup in the cloud industry.</li>
                                <li>Quantaleap's vision is to revolutionize operations in the cloud by leveraging data analysis and machine learning techniques.</li>
                                <li>Carefully tailored data-driven insights, paired with a powerful serverless automation engine, will allow companies to put cloud operations on autopilot.</li>
                                </ul>
                            </p>
                            <p>
                                <span :class="classes.p_header">Main technologies:</span>
                                <ul>
                                    <li>Secure and scalable public cloud infrastructure on Microsoft Azure and Kubernetes</li>
                                    <li>Automated integration and deployment, fully covered by infrastructure as code</li>
                                    <li>Web app and REST API development with TypeScript and React JS</li>
                                    <li>Backend microservice development in Go</li>
                                </ul>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Cloud DevOps Engineer<span :class="classes.date_box">01/2021 ‒ 03/2022</span></v-card-title>

                        <v-card-subtitle>At <a href="https://www.mercedes-benz-techinnovation.com/en/">Mercedes-Benz Tech Innovation</a></v-card-subtitle>
                        <v-card-text>
                            <p>
                                <span :class="classes.p_header">Project and Team:</span>
                                <ul :class="classes.text">
                                    <li>Internal Container-as-a-Service platform hosting over 800 Kubernetes clusters worldwide, built mainly from open source software with custom components and optimizations</li>
                                    <li>Enterprise development ecosystem with multiple teams, following a Scrum-inspired agile development workflow</li>
                                    <li>Full DevOps workflow ("You build it, you run it"), including on-call duty</li>
                                    <li>Fully automatic continuous integration and deployment</li>
                                </ul>
                            </p>
                            <p>
                                <span :class="classes.p_header">Personal Tasks:</span>
                                <ul :class="classes.text">
                                    <li>Design, coordination, and development of a global-scale monitoring system for the multi-region Kubernetes platform based on <a href="https://prometheus.io/">Prometheus</a> and <a href="https://thanos.io/">Thanos</a></li>
                                    <li>System for automatic remediation of inconsistencies in customer-, billing-, and cluster-data</li>
                                    <li>Web-based console for simple administration of customer clusters</li>
                                    <li>Development and maintenance of various components including billing, log shipping, alerting, customer-facing and internal APIs, and documentation</li>
                                    <li>Main languages and technologies: Go, Kubernetes, Helm, Kustomize, Bazel, Ansible</li>
                                </ul>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Research associate<span :class="classes.date_box">01/2015 ‒ 08/2020</span></v-card-title>

                        <v-card-subtitle>At <a href="https://www.dos.tu-berlin.de/menue/distributed_and_operating_systems/">Distributed and Operating Systems Group</a>, Technical University Berlin</v-card-subtitle>
                        <v-card-text>
                            <p>
                                <span :class="classes.p_header">Development & Operations:</span>
                                <ul :class="classes.text">
                                    <li>Agile development of data stream processing framework <a href="https://github.com/bitflow-stream/">Bitflow</a>, based on Kubernetes, including high-performance <a href="https://github.com/bitflow-stream/go-bitflow-collector">monitoring agent</a>, <a href="https://bitflow.readthedocs.io/projects/bitflow-antlr-grammars/en/latest/bitflow-script/">DSL for dataflow graphs</a> and <a href="https://github.com/bitflow-stream/bitflow-k8s-operator">Kubernetes operator</a></li>
                                    <li>Installation and maintenance of dozens of testbed infrastructures ranging from 1 to 100 physical nodes</li>
                                    <li>Private cloud infrastructures powered by OpenStack and Kubernetes</li>
                                    <li>Automation using Ansible, Kolla, Kubespray, Heat, and Helm</li>
                                    <li>Administration of SDN-based networks</li>
                                </ul>
                            </p>
                            <p>
                                <span :class="classes.p_header">Projects with industry partners:</span>
                                <ul :class="classes.text">
                                    <li>Self-healing in cloud platforms, driven by cross-layer monitoring and real-time data analysis</li>
                                    <li>High-availability in cloud platforms, specifically in: Cloud controller services, service and VM storage, VM images and snapshots</li>
                                    <li>Continuous monitoring and real-time analysis of water pumps, alerting of malfunctions, proactive maintenance</li>
                                    <li>Data-driven optimization of parameters for the production process of electronic parts (IoT, Industry 4.0)</li>
                                </ul>
                            </p>
                            <p>
                                <span :class="classes.p_header">Research and teaching:</span>
                                <ul :class="classes.text">
                                    <li>Numerous <a href="/publications">publications</a> on the topics of cloud architecture, monitoring, and data analysis</li>
                                    <li>Supervised numerous bachelor's and master's theses in topics related to my research and dissertation</li>
                                    <li>Designed, presented and graded practical programming excercises and written exams</li>
                                    <li>Taught courses: Cloud Computing, Distributed Systems, Operating Complex IT-Systems</li>
                                </ul>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Student employee & software development internship<span :class="classes.date_box">10/2012 ‒ 10/2013</span></v-card-title>
                        <v-card-subtitle>At <a href="https://www.sap.com/about/sap-labs-silicon-valley.html">SAP Palo Alto</a></v-card-subtitle>
                        <v-card-text>
                            <ul :class="classes.text">
                                <li>Worked with SQL and In-Memory Databases, building proofs-of-concept for analysis of large data</li>
                                <li>Developed prototype system to analyze big data of online games targeting to increase conversion rate, revenue and reduce churn behavior</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Tutor & Student assistant<span :class="classes.date_box">04/2011 ‒ 09/2014</span></v-card-title>
                        <v-card-subtitle>At <a href="https://hpi.de/en/the-hpi/overview.html">Hasso Plattner Institute</a>, groups <a href="https://hpi.de/en/research/research-groups/software-architecture.html">Software Architecture</a> and <a href="https://hpi.de/en/research/research-groups/operating-systems-and-middleware.html">Operating Systems and Middleware</a></v-card-subtitle>
                        <v-card-text>
                            <p>
                                <span :class="classes.p_header">Projects:</span>
                                <ul :class="classes.text">
                                    <li>Smalltalk-based development tool to visualize, debug and edit the architecture of software modules</li>
                                    <li>Software mutation-engine in Smalltalk</li>
                                    <li>Bachelor's project "Scripting objects for the web": Designed and implemented a system to reduce the complexity of programming web applications; a <a href="https://www.hpi.uni-potsdam.de/hirschfeld/publications/media/ThamsenGulenkoPerscheidKrahnHirschfeldThomas_2012_OrcaASingleLanguageWebFrameworkForCollaborativeDevelopment_AuthorsVersion.pdf">published paper</a> summarizes the project</li>
                                    <li>Contributed to InstantLab, a platform for software- and operating system experiments in the browser, backed by cloud computing technology</li>
                                    <li>Master's project: Computer vision for supporting divers under water; used OpenCV for object tracking</li>
                                </ul>
                            </p>
                            <p>
                                <span :class="classes.p_header">Teaching:</span>
                                <ul :class="classes.text">
                                    <li>Mentored and guided students, designed and corrected practical programming exercises</li>
                                    <li>Courses: Programming Concepts 2, Software Technology 2, Concepts of Parallel Programming, Dependable Systems</li>
                                </ul>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Software developer<span :class="classes.date_box">12/2006 ‒ 04/2011</span></v-card-title>
                        <v-card-subtitle>At <a href="https://www.4flow.com/about-4flow/facts.html">4flow</a> and <a href="https://www.optimal-systems.de/en/about-us">Optimal Systems</a></v-card-subtitle>
                        <v-card-text>
                            <ul :class="classes.text">
                                <li>Java-based graphical tool for creating and executing test suites</li>
                                <li>Java backend development and optimization</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>

                <SectionTitle name="Education" />
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Ph.D. (Dr.-Ing.)<span :class="classes.date_box">01/2015 ‒ 08/2020</span></v-card-title>
                        <v-card-subtitle><a href="https://www.dos.tu-berlin.de/menue/distributed_and_operating_systems/">Distributed and Operating Systems Group</a>, Technical University Berlin</v-card-subtitle>
                        <v-card-text>
                            <ul :class="classes.text">
                                <li>Grade: Cum laude</li>
                                <li>Specialization: Reliable distributed systems</li>
                                <li>Thesis: <a href="https://depositonce.tu-berlin.de/bitstream/11303/11458/5/gulenko_anton.pdf">Autonomic Self-healing in Cloud Computing Platforms</a></li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Master of Science<span :class="classes.date_box">10/2011 ‒ 01/2015</span></v-card-title>
                        <v-card-subtitle>IT-Systems Engineering, Hasso Plattner Insitute</v-card-subtitle>
                        <v-card-text>
                            <ul :class="classes.text">
                                <li>Grade: 1.2 (A)</li>
                                <li>Specialization: Execution environments and optimization</li>
                                <li>Thesis: <a href="/gulenko_master_thesis.pdf">Allocation Removal in Modern Object Oriented VMs</a></li>
                                <li>Publication: <a href="https://dl.acm.org/doi/pdf/10.1145/2816707.2816716">Language-Independent Storage Strategies for Tracing-JIT-Based Virtual Machines</a></li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Bachelor of Science<span :class="classes.date_box">10/2008 ‒ 07/2011</span></v-card-title>
                        <v-card-subtitle>IT-Systems Engineering, Hasso Plattner Insitute</v-card-subtitle>
                        <v-card-text>
                            <ul :class="classes.text">
                                <li>Grade: 1.6 (A-)</li>
                                <li>Specialization: Software engineering and collaboration</li>
                                <li>Thesis: <a href="/gulenko_bachelor_thesis.pdf">Integrating JavaScript Language Features into Smalltalk</a></li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>

                <SectionTitle name="Skills" />

                <v-col cols=12 class="d-flex" align-self="stretch">
                    <v-card class="flex-grow-1" outlined>
                        <v-card-title :class="classes.card_header">Certifications</v-card-title>
                        <v-card-subtitle>
                            <ul :class="classes.text">
                                <li>
                                    <a href="https://learn.microsoft.com/en-us/certifications/azure-administrator/">Microsoft Azure: Administrator Associate</a><span :class="classes.date_box">(04/2022)</span>
                                </li>
                            </ul>
                        </v-card-subtitle>
                    </v-card>
                </v-col>

                <v-col cols=12 md=6 class="d-flex" align-self="stretch" v-for="skill in skills" :key="skill.skill">
                    <v-card class="flex-grow-1" outlined>
                        <v-card-title :class="classes.card_header">{{ skill.skill }}</v-card-title>
                        <v-card-subtitle>
                            <ul :class="classes.text">
                                <li v-for="part in skill.parts" :key="part">{{ part }}</li>
                            </ul>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols=12>
                    <v-card outlined>
                        <v-card-title :class="classes.card_header">Languages</v-card-title>
                        <v-card-subtitle >
                            <v-row no-gutters justify="space-between" align="center">
                                <v-card class="col-6 col-sm-3" flat v-for="lang in languages" :key="lang.lang">
                                    <v-card-title class="text-h6">{{ lang.lang }}</v-card-title>
                                    <v-card-subtitle :class="classes.language">{{ lang.prof }}</v-card-subtitle>
                                </v-card>
                            </v-row>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import SectionTitle from "./SectionTitle"
import PageTitle from "./PageTitle"

export default {
    name: "Resume",
    components: { SectionTitle, PageTitle },
    computed: {
      bigMobile: function() { return this.$vuetify.breakpoint.smAndUp },
      desktop: function() { return this.$vuetify.breakpoint.mdAndUp },
    },
    data() {
        return {
            "classes": {
                "text": "text-body-1",
                "card_header": "text-left text-h5 d-flex",
                "p_header": "text-subtitle-2",
                "language": "text-body-2",
                "date_box": "text-body-2 pa-1 ml-auto",
            },
            "contacts": [
                { link: "https://antongulenko.info", text: "antongulenko.info", icon: "mdi-web" },
                { link: "https://www.linkedin.com/in/antongulenko", text: "antongulenko", icon: "mdi-linkedin" },
                { link: "https://github.com/antongulenko", text: "antongulenko", icon: "mdi-github" },
                { link: "mailto:anton.gulenko@gmail.com", text: "anton.gulenko@gmail.com", icon: "mdi-at" },
                { link: "tel:+4915253452132", text: "+49 152 53452132", icon: "mdi-phone" },
            ],
            "skills": [
                { skill: "Programming", parts: [
                    "Compiled languages: Go, C",
                    "Object-oriented programming: Java, Python, Smalltalk",
                    "Web Development: TypeScript, Javascript, Node, React, VueJS, HTML, CSS"
                ]},
                { skill: "Cloud & Container", parts: [
                    "Container: Kubernetes, Docker, Docker Swarm, Kubespray",
                    "Public Clouds: Microsoft Azure, Google Cloud Platform, Amazon AWS",
                    "Private Clouds: OpenStack, Kolla"
                ]},
                { skill: "DevOps & Administration", parts: [
                    "CI/CD: Jenkins, Gitlab CI",
                    "Infrastructure as code: Ansible, Helm, Heat",
                    "Linux server: Debian, Shell & Scripting, SSH"
                ]},
                { skill: "Data Analysis", parts: [
                    "Data stream processing",
                    "Big Data Frameworks: Flink, Spark",
                    "Applied ML: Anomaly detection, classification, decision making"
                ]}
            ],
            "languages": [
                { lang: "German", prof: "Native speaker" },
                { lang: "Russian", prof: "Native speaker" },
                { lang: "English", prof: "Full working proficiency" },
                { lang: "French", prof: "Limited working proficiency" },
            ]
        }
    }
}
</script>

<style scoped>
</style>
