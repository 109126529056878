<template>
    <v-row dense class="tab-row flex-nowrap">
        <v-spacer class="ml-auto" />
        <v-btn v-for="navItem in navigation" :key="navItem.name" 
            elevation="0"
            @click="navigate(navItem.target)"
            :href="root_page + '#' + asHtmlId(navItem.target)"
            :color="desktop ? '' : 'linkButton'" :large="desktop" :tile="desktop" :text="desktop"
            class="mx-1 px-2 text-subtitle-1 text-none"
            :class="[desktop ? 'titleText--text' : 'linkButtonText--text']">
                {{ navItem.name }}
        </v-btn>
        <v-spacer class="mr-auto d-none d-md-flex" />
    </v-row>
</template>

<script>
import goTo from 'vuetify/es5/services/goto'
import { asHtmlId } from '../helper'

export default {
    name: "PageTabs",
    props: [ "root_page", "navigation" ], // list of objects with .name and .target
    computed: {
        desktop: function() { return this.$vuetify.breakpoint.mdAndUp },
    },
    methods: {
        asHtmlId,
        navigate(section) {
            goTo("#" + asHtmlId(section), {duration: 250, easing: "easeInCubic"})
        },
    }
}
</script>

<style scoped>
    .tab-row {
        overflow-x: auto;
    }
</style>
