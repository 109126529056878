<template>
    <v-row justify="space-around">
        <v-col cols=11 xl=6>
            <v-row dense justify="center" align="center">
                <PageTitle text="Anton Gulenko, PhD" classes="order-md-1" />
                <div class="order-md-3 pl-md-10" :class="{'col': !desktop}">
                    <v-img src="/profile.jpg" class="image-border avatar-image" alt="Avatar picture" max-width=400 />
                    <v-row class="my-4" justify="center">
                        <a :href="item.link" v-for="item in contacts" :key="item.link">
                            <v-icon color="contactIcon" size=80>{{ item.icon }}</v-icon>
                        </a>
                    </v-row>
                </div>
                <v-col cols=12 md=6 order-md=2 class="text-body-1 text-justify pb-0">
                    <p class="d-flex align-center">
                        <span class="font-weight-bold text-h6 text-no-wrap mr-4">TL;DR</span>
                        <span>
                        I am a Cloud and DevOps expert with a strong background in research and reliable infrastructures.
                        </span>
                    </p>
                    <p>
                    Welcome to my website!
                    Feel free to look around and get to know my background.
                    If you feel we have shared interests, please <router-link to="/contact">get in touch</router-link>.
                    I am passionate about pushing the boundaries of modern IT infrastructures.
                    Technology continues to make complexity more manageable than ever, and I am proud to contribute to this development.
                    </p>
                    <p>
                    I equally love both the <span class="font-italic">development</span> and the <span class="font-italic">operations</span> parts of DevOps.
                    I started my programming career as a 16 year old back in high school, and have been passionate about software engineering ever since, strongly focussing on &ldquo;systems&rdquo; and backend development (with a few excursions into the frontend world here and there).
                    On the other hand, I am fascinated by Cloud and container technologies, server administration, automation, and CI/CD pipelines.
                    While completing my Ph.D. in cloud computing, I implemented and maintained countless cloud and service infrastructures of various sizes and complexities. The topic of my dissertation is &ldquo;Self-Healing in Cloud Computing Platforms&rdquo; – a machine learning based approach to reliability in the cloud.
                    </p>
                    <p>
                    If this little introduction sparked your interest, please <router-link to="/resume">read on</router-link>. I would be happy to hear from you!
                    </p>
                </v-col>
                <v-col cols=12 class="d-md-none py-3"><v-divider></v-divider></v-col>
                <v-col cols=12 class="d-md-none"><BottomNav :navigation="bottomLinks" /></v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import BottomNav from "./BottomNav"
import PageTitle from "./PageTitle"

export default {
    name: "Home",
    components: { BottomNav, PageTitle },
    computed: {
      desktop: function() { return this.$vuetify.breakpoint.mdAndUp },
    },
    data() {
        return {
            "contacts": [
                { link: "mailto:anton.gulenko@gmail.com", text: "anton.gulenko@gmail.com", icon: "mdi-at" },
                { link: "https://github.com/antongulenko", text: "antongulenko", icon: "mdi-github" },
                { link: "https://www.linkedin.com/in/antongulenko", text: "antongulenko", icon: "mdi-linkedin" },
            ],
            bottomLinks: [
                { link: "/resume", text: "Resume" },
                { link: "/publications", text: "Publications" },
                { link: "/contact", text: "Contact" },
            ]
        }
    }
}
</script>
